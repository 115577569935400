import React from 'react';
import { Link } from 'react-router-dom';

function home() {
  return (
    <div>
      <div className='home h-screen flex flex-col gap-5 justify-center items-center '>
        <h1>Welcome to Auth Application</h1>
        <Link to='/login'>
          <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
            Login
          </button>
        </Link>
        <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
          <a href='https://92e3-2402-4000-b280-ddd7-c91e-81e7-cf51-c80a.ngrok-free.app/api/auth/google'>
            Login with Google
          </a>
        </button>
      </div>
    </div>
  );
}

export default home;
