import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './page/home';
import Login from './page/login.jsx';
import Profile from './components/Profile.jsx';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/login'
            element={<Login />}
          />
          <Route
            path='/dashboard'
            element={<Dashboard />}
          />
          <Route
            path='/profile'
            element={<Profile />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
