import React from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const logout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      handleLogout();
      window.location.reload();
    }
  };

  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className='bg-white p-8 rounded-lg shadow-lg flex flex-col justify-center items-center h-screen'>
      <h1 className='text-3xl font-bold mb-4'>Profile</h1>
      <img
        src={user?.image || 'https://via.placeholder.com/150'}
        alt={user?.name}
        className='w-32 h-32 rounded-full mb-4'
      />
      <p className='text-xl'>
        Welcome <strong className='font-bold'>{user?.name}</strong>!
      </p>
      <button
        className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4'
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
};

export default Profile;
