import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Dashboard() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(
        'https://92e3-2402-4000-b280-ddd7-c91e-81e7-cf51-c80a.ngrok-free.app/api/user',
        { withCredentials: true }
      )
      .then((response) => setUser(response.data))
      .catch((error) => console.error('Error fetching user data:', error));
  }, []);

  return (
    <div>
      <h1>Dashboard</h1>
      {user ? (
        <div className='flex flex-col gap-5 justify-center items-center'>
          <img
            src={user.image || 'https://via.placeholder.com/150'}
            alt={user.name}
            className='w-32 h-32 rounded-full'
          />
          <h2>Welcome, {user.name}</h2>
          <p>Email: {user.email}</p>
          <button className='bg-red-700 text-white font-bold py-2 px-4 rounded'>
            <a href='https://92e3-2402-4000-b280-ddd7-c91e-81e7-cf51-c80a.ngrok-free.app/api/auth/logout'>
              Logout
            </a>
          </button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Dashboard;
